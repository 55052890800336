<template>
  <div v-if="!defaultSender?.isAcknowledged">
    <v-row>
      <v-col cols="12">
        <v-card
          outlined
          class="pa-4"
          style="overflow:hidden"
        >
          <VerificationIdle
            v-if="isIdle"
            :default-sender="defaultSender"
            :organization="organization"
            :on-default-sender-created="onDefaultSenderCreated"
          />

          <VerificationSent
            v-else-if="isSent"
            :default-sender="defaultSender"
            :default-sender-created="defaultSenderCreated"
            :check-status="() => checkStatus(true)"
          />

          <VerificationVerified v-else-if="isVerified" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CHECK_DEFAULT_SENDER_VERIFICATION from './queries/CheckDefaultSenderVerification.gql'
import HOPPERMATION_DEFAULT_SENDER from '@/modules/emailVerification/queries/HoppermationDefaultSender.gql'
import HOPPERMATION_ORGANIZATION from '@/modules/emailVerification/queries/HoppermationOrganization.gql'
import bus, { eventNames } from '@/lib/eventBus'
import VerificationIdle from './VerificationIdle.vue'
import VerificationSent from './VerificationSent.vue'
import VerificationVerified from './VerificationVerified.vue'

export default {
  components: { VerificationIdle, VerificationSent, VerificationVerified },
  props: {
    defaultSender: {
      type: Object,
      default: () => undefined
    }
  },
  data () {
    return {
      loading: false,
      defaultSenderCreated: false
    }
  },
  computed: {
    isIdle () {
      return this.organization?.status === 'INACTIVE'
    },
    isSent () {
      return this.organization?.status === 'PENDING'
    },
    isVerified () {
      return this.organization?.status === 'ACTIVE' && !this.defaultSender?.isAcknowledged
    }
  },
  created () {
    this.checkStatus()
  },
  apollo: {
    defaultSender: {
      query: HOPPERMATION_DEFAULT_SENDER,
      pollInterval: 60000,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    organization: {
      query: HOPPERMATION_ORGANIZATION,
      pollInterval: 60000,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  },
  methods: {
    onDefaultSenderCreated () {
      this.defaultSenderCreated = true
    },
    async checkStatus (showSuccessSnackbar = false) {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: CHECK_DEFAULT_SENDER_VERIFICATION,
          variables: { companyId: this.$auth.user.companyId },
          refetchQueries: [{
            query: HOPPERMATION_ORGANIZATION,
            variables: { companyId: this.$auth.user.companyId }
          }]
        })
        if (showSuccessSnackbar) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.email-verification.success') })
        }
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.email-verification.error') })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
